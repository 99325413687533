import { useForm } from "react-form";
import { useState } from "react";

import { Button } from "../../../../components";
import { EmailAddressInput } from "./inputs/EmailAddressInput";
import { useQueryClient, UseQueryResult } from "react-query";
import { Trans, useTranslation } from "react-i18next";
import { UmojaToast } from "../../../../components/Popups/UmojaToast";

const { REACT_APP_ACCOUNT_SETTINGS_ENDPOINT } = process.env;

interface AccountInformationFormProps {
  account: UseQueryResult<any, unknown>;
  setEditEmailMode: Function,
  editEmailMode: boolean,
  setEditPasswordMode: Function
}

export const AccountInformationForm = ({
  account,
  setEditEmailMode,
  editEmailMode,
  setEditPasswordMode
}: AccountInformationFormProps) => {
  const [saving, setSaving] = useState(false);

  const { t } = useTranslation()
  const { data } = account;
  const QueryClient = useQueryClient()

  const AccountInformationFormInstance = useForm({
    onSubmit: async (values: Record<string, any>, instance) => {
      setSaving(true);
      // handle api here
      const response = await fetch(
        `${REACT_APP_ACCOUNT_SETTINGS_ENDPOINT}/${data.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          body: JSON.stringify({
            email: values.settings.email,
          }),
        }
      );

      if (!response.ok) {
        UmojaToast.show({
          message: t("error_updating_email_address"),
          intent: "danger",
          icon: "issue",
        });
      } else {
        // setSuccess(true);
        QueryClient.invalidateQueries("team")
        QueryClient.invalidateQueries("me")
        UmojaToast.show({
          message: t("successfully_updated_email"),
          intent: "success",
          icon: "tick",
        });
      }
      setSaving(false);
      setEditEmailMode(false);
    },
    // debugForm: true,
  });

  const { meta } = AccountInformationFormInstance;
  
  return (
    <>
      <AccountInformationFormInstance.Form>
        <div className="settings__card-header">
          <div className="settings__card-title_wrapper">
            <h4 className="settings__card-title">
              <Trans i18nKey="account_information" />
            </h4>

            <p className="settings__card-subtitle">
              <Trans i18nKey="account_information_subtitle" />
            </p>
          </div>
          {!editEmailMode && (
            <Button
              intent="primary"
              text={<Trans i18nKey="edit" />}
              loading={saving}
              onClick={() => {
                setEditEmailMode(true)
                setEditPasswordMode(false)
              }
              }
            />
          )}
          {editEmailMode && (
            <Button
              type="submit"
              intent="primary"
              text={<Trans i18nKey="save" />}
              loading={saving}
              disabled={!(meta.canSubmit && meta.isTouched)}
            />
          )}
        </div>
        <ul className="settings__card-input-list">
          <li className="settings__card-input-list-item">
            <label>
              <Trans i18nKey="name" />
            </label>
            <p>
              {account.data.first_name} {account.data.last_name}
            </p>
          </li>
          <EmailAddressInput disabled={editEmailMode} email={account.data.email} />
        </ul>
      </AccountInformationFormInstance.Form>
    </>
  );
};
