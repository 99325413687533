import { Layout, Table, UmojaHeader } from "../../components";
import { Row, UseTableCellProps } from "react-table";

import { Tag } from "@blueprintjs/core";

import useOrganizationTeam from "../../hooks/useOrganizationTeam";
import { useAccount } from "../../hooks";
import { useOrganizationId } from "../../hooks/useOrganizationId";
import { Trans, useTranslation } from "react-i18next";

export type UmojaAccount = {
  id: number;
  account_id: number;
  first_name: string;
  last_name: string;
  email: string;
  is_email_verified: string;
  role: string;
  created_at: Date;
  organization_id: number;
};

const OrganizationTeamScreen = () => {
  const account = useAccount();
  const { t } = useTranslation();

  const organizationId = useOrganizationId();

  const { data } = useOrganizationTeam(organizationId);

  const columns = [
    {
      Header: <Trans i18nKey="name" />,
      accessor: "last_name",
      Cell: (instance: UseTableCellProps<UmojaAccount>) => {
        return `${instance.row.original.first_name} ${instance.row.original.last_name}`;
      },
    },
    {
      Header: <Trans i18nKey="email_address" />,
      accessor: "email",
      Cell: (instance: UseTableCellProps<UmojaAccount>) => {
        const { is_email_verified } = instance.row.original;
        const EmailVerifiedTag = (
          <Tag minimal round intent={is_email_verified ? "success" : "warning"}>
            {is_email_verified ? t("active") : t("Pending")}
          </Tag>
        );
        return (
          <span>
            {instance.value} {EmailVerifiedTag}
          </span>
        );
      },
    },
    {
      Header: <Trans i18nKey="role" />,
      accessor: "role",
    },
  ];

  // const dismissModal = () => {
  //   history.push(`/dashboard/organizations/${organizationId}/team`);
  // };

  const onRowClick = (row: Row<any>) => {
    // team drawer inactive for pilot
    // history.push(
    //   `/dashboard/organizations/${organizationId}/team/${row.original.id}`
    // );
  };

  return (
    <Layout.Container>
      <UmojaHeader title={<Trans i18nKey="team" />} user={account.data} />
      <Table columns={columns} data={data.map((data: Record<string, any>) => ({
        ...data,
        role: t(data.role)
      }))} onRowClick={onRowClick} />
      {/* team drawer inactive for pilot */}
      {/* <Drawer isOpen={!!accountId} canOutsideClickClose onClose={dismissModal}>
        <div style={{ padding: 10 }}>
          <div>
            <h1>Hello Team</h1>
          </div>
        </div>
      </Drawer> */}
    </Layout.Container>
  );
};

export default OrganizationTeamScreen;
