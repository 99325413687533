import { useState, useEffect } from "react";
import { Icon, Tag } from "@blueprintjs/core";
import { useForm } from "react-form";
import { Button } from "../../../../components";
import { ProjectMerchantsInput } from "./inputs/ProjectMerchantsInput";
import { ProjectParticipantsInput } from "./inputs/ProjectParticipantsInput";
import { Trans, useTranslation } from "react-i18next";
import { UmojaToast } from "../../../../components/Popups/UmojaToast";
import { OrganizationProject } from "../../../Wallets/OrganizationWallets";
import  { AtLeast } from "../../../../hooks/useRole";

interface ParticipantEnrollmentFormProps {
  project: OrganizationProject;
  activeStep: any,
  setActiveStep: any,
  account: any,
  role: string
}

export const ParticipantEnrollmentForm = ({ project, activeStep,setActiveStep,account, role }: ParticipantEnrollmentFormProps) => {
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);
  const [disableUpload, setDisableUpload] = useState(false);

  const [uploadType, setUploadType] = useState<null | string>(null);


  const onboardingStep = 3;
  const disabled = project
    ? Boolean(project.project_onboarding_step < onboardingStep)
    : true;

  const readOnly = Boolean(project?.published_at);

  //
  useEffect(() => {
    if (readOnly || disabled) {
      setDisableUpload(true);
    }

    localStorage.removeItem('recipient');
    localStorage.removeItem('merchant');
  }, [readOnly, disabled]);

  // support adding participants to projects that has already been launched
  const uploadParticipantsEndpoint = readOnly ? "add_participants" : `onboarding/${onboardingStep}`;

  const ParticipantEnrollmentFormInstance = useForm({
    onSubmit: async (values) => {
      setSaving(true);
      const data = new FormData();
      data.append("project.recipients", values.project_recipients);
      data.append("project.merchants", values.project_merchants);
      // handle api here
      const response = await fetch(
        `${process.env.REACT_APP_CREATE_PROGRAM_ENDPOINT}/${project.id}/${uploadParticipantsEndpoint}` as string,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          body: data,
        }
      );
      // network/server failure
      if (!response.ok) {
        UmojaToast.show({
          message: `${t("problem_adding_details")}`,
          intent: "danger",
          icon: "issue",
        });
        setSaving(false);

        return;
      }
      // success
      UmojaToast.show({
        message: `${t("successfully_enrolled_users")}`,
        intent: "success",
        icon: "tick",
      });
      account.refetch(); // refactor to useMutation
      setActiveStep(4);
      setSaving(false);
    },
    // debugForm: true,
  });

  const { values } = ParticipantEnrollmentFormInstance;

  return (
    <ParticipantEnrollmentFormInstance.Form>
      <div className="settings__card-header">
        <div className="settings__card-title_wrapper">
          <h2 className="settings__card-step">
            <Trans i18nKey="step_three" />
          </h2>
          <h4 className="settings__card-title">
            <Trans i18nKey="enroll_participants" />
            <ParticipantEnrollmentFormStatusIndicator
              project={project}
              onboardingStep={onboardingStep}
            />
          </h4>

          <p className="settings__card-subtitle">
            <Trans i18nKey="enroll_participants_subtitle" />
          </p>
        </div>
        <Button icon="double-caret-vertical" onClick={() => setActiveStep(3)} />
      </div>
      {activeStep === 3 && (
        <>
          <div className="settings__card-upload_select_wrapper">
            <h2 className="settings__card-step">{t("select_data_source")}</h2>
            <ul className="settings__card-input-list">
              <li className="settings__card-input-list-item">
                <Button
                  fill
                  text={t("upload_custom_excel")}
                  className="settings__card-file_upload_select"
                  disabled={disableUpload}
                  onClick={() =>
                    uploadType
                      ? setUploadType(null)
                      : setUploadType("local_excel")
                  }
                  alignText="left"
                  icon={
                    <Icon
                      icon={uploadType === "local_excel" ? "selection" : "circle"}
                      iconSize={15}
                      color="#5C7080"
                      style={{ paddingBottom: 2 }}
                    />
                  }
                />
              </li>
              <li className="settings__card-input-list-item">
                <Button
                  fill
                  text={t("import_data_from_koboToolbox")}
                  className="settings__card-file_upload_select"
                  disabled
                  alignText="left"
                  icon={
                    <Icon
                      icon="circle"
                      iconSize={15}
                      color="#BFCCD6"
                      style={{ paddingBottom: 2 }}
                    />
                  }
                />
              </li>
            </ul>
            <h2 className="settings__card-step">{t("upload_data")}</h2>
            <ul className="settings__card-input-list">
              <li className="settings__card-input-list-item flex-col">
                <ProjectParticipantsInput
                  disabled={!uploadType}
                  programId={project?.id}
                />
              </li>
              <li className="settings__card-input-list-item flex-col">
                <ProjectMerchantsInput
                  disabled={!uploadType}
                  programId={project?.id}
                />
              </li>
              {/* currently don't support individual uploads, but we could in the future */}
              {/* <li className="settings__card-input-list-item">
                <ProjectMerchantsInput disabled={!uploadType} />
              </li> */}
            </ul>
          </div>
          <div className="settings__card-step_button_container">
            {readOnly ? <Button
              type="button"
              intent="primary"
              text={`${t('add_more_participants')}`}
              onClick={() => setDisableUpload(false)}
              disabled={!AtLeast.Admin(role)}
            />
              : <div></div>
            }

            <div className="settings__card-step_button_wrapper">
              <p>
                {disabled
                  ? `${t("complete_previous_steps")}`
                  : `${t("continue_when_ready")}`}
              </p>
              <Button
                type="submit"
                intent="primary"
                text={
                  (disableUpload && project?.project_onboarding_step > onboardingStep) ? (
                    t('update')
                  ) : project?.project_onboarding_step > onboardingStep ? `${t('update')}` : (
                    <Trans i18nKey="next_step" />
                  )
                }
                loading={saving}
                disabled={!readOnly ?
                  !values.project_recipients ||
                  !values.project_merchants || disableUpload
                  : !(values.project_recipients || values.project_merchants) || disabled
                }
              />
            </div>
          </div>
        </>
      )}
    </ParticipantEnrollmentFormInstance.Form>
  );
};

interface SettingsFormStatusIndicatorProps {
  project: OrganizationProject;
  onboardingStep: number;
}

export const ParticipantEnrollmentFormStatusIndicator = ({
  project,
  onboardingStep,
}: SettingsFormStatusIndicatorProps) => {
  const { t } = useTranslation();
  if (!project) {
    return null;
  }

  return project.project_onboarding_step > onboardingStep ? (
    <Tag
      className="settings__status_indicator"
      htmlTitle="incomplete section"
      intent="success"
    >
      {t("section_complete")}
    </Tag>
  ) : (
    <Tag
      className="settings__status_indicator"
      htmlTitle="incomplete section"
      intent="warning"
    >
      {t("section_incomplete")}
    </Tag>
  );
};
