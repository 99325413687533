/* eslint-disable jsx-a11y/accessible-emoji */
// @ts-nocheck - may need to be at the start of file
import { useTranslation } from "react-i18next";
import { Button } from "../../components";

export const Pagination = ({ gotoPage, previousPage, nextPage, pageCount, canPreviousPage, canNextPage, pageOptions, pageIndex }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="pagination">
        <Button disabled={!canPreviousPage} onClick={() => gotoPage(0)} className={"pagination_button"}>
          {t('first')}
        </Button>
        <Button  disabled={!canPreviousPage} onClick={() => previousPage()} className={"pagination_button"}>
          {t('previous')}
        </Button >
        <Button  disabled={!canNextPage} onClick={() => nextPage()} className={"pagination_button"}>
          {t('next')}
        </Button >
        <Button  disabled={!canNextPage} onClick={() => gotoPage(pageCount - 1)} className={"pagination_button"}>
          {t('last')}
        </Button >
      </div>
      <p style={{ padding: "10px"}}>
        {`${t('page')}`} {pageIndex + 1} {`${t('of')}`} {pageOptions.length}
      </p>
    </>
  )
}
