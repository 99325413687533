import "./UmojaChartWidget.css";
import {
  VictoryBar,
  VictoryGroup,
  VictoryLegend,
  VictoryAxis,
  PaddingProps,
  VictoryStack,
  VictoryTooltip
} from "victory";
import { useTranslation } from "react-i18next";
import { formatDecimalNumber } from "../../../utils";

export interface UmojaDemographicInsightChartProps {
  theme: any;
  data: any;
  title: string;
  padding?: PaddingProps;
  tickValues: any[];
  currency: string;
}

const UmojaDemographicInsightChart = ({
  theme,
  data,
  title,
  padding,
  tickValues,
  currency
}: UmojaDemographicInsightChartProps) => {
  const { t } = useTranslation();

  
   if (!data?.length) {
     return <div>{ t("no_data")}</div>;
  }
  return (
    <>
      <div className="demographic_insight_chart">
        <VictoryGroup
          theme={theme}
          padding={padding}
          horizontal
          animate={{
            duration: 1000,
            onLoad: { duration: 500 },
          }}
          height={(tickValues.length * 16) + 40}
        >
          <VictoryLegend
            title={`${title}`}
          />
          <VictoryAxis
            tickValues={tickValues}
          />

          <VictoryStack>
            <VictoryBar
              style={{
                data: { fill: "#333333", width: 4, stroke: "none" }
              }}
              data={data[1]?.male}
              labelComponent={
                <VictoryTooltip
                  pointerOrientation="top"
                  flyoutStyle={{ fill: "white", strokeWidth: 0.5 }}
                  flyoutPadding={{ top: 2, bottom: 2, left: 7, right: 7 }}
                  centerOffset={{ x: -40 }}
                />
              }
              labels={({ datum }) => `${currency}${(datum.y as number).toFixed(2)}`}
            />

            <VictoryBar
              style={{
                data: { fill: "#28ceae", width: 4, stroke: "none" }
              }}
              data={data[2]?.female}
              labelComponent={
                <VictoryTooltip
                  pointerOrientation="top"
                  flyoutStyle={{ fill: "white", strokeWidth: 0.5 }}
                  flyoutPadding={{ top: 2, bottom: 2, left: 7, right: 7 }}
                  centerOffset={{ x: -40 }}
                />
              }
              labels={({ datum }) => `${currency}${formatDecimalNumber(datum.y)}`}
            />
            <VictoryBar // add extra empty bar to display the total transaction label
              style={{
                data: { fill: "#ed8310", width: 4, stroke: "none" }
              }}
              data={data[5]?.empty}
              labels={({ datum }) => `${currency}${formatDecimalNumber(datum.z)}`}
            />
          </VictoryStack>
        </VictoryGroup>
      </div>
      <div className="demographic_insight_chart__footer">
        <div className="flex-display">
          <div className="flex-display margin-right-15">
            <div className="legend black"></div>
            <div className="demographic_insight_chart_font">{t("male_total_transactions")}</div>
          </div>
          <div className="flex-display margin-right-15">
            <div className="legend green"></div>
            <div className="demographic_insight_chart_font">{t("female_total_transactions")}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UmojaDemographicInsightChart;
