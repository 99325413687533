import { Route, Switch, Redirect } from "react-router-dom";

import DashboardHomeScreen from "../Home/DashboardHome";
import DashboardActivityScreen from "../ActivityExplorer/DashboardActivity";

import OrganizationCreateScreen from "../OrganizationCreate";
import ProjectCreateScreen from "../ProgramCreate";
import OrganizationWalletsScreen from "../Wallets/OrganizationWallets";
import OrganizationSettingsScreen from "../Settings/Settings";
import OrganizationTeamScreen from "../Organization/Team";

type Organization = {
  id: number;
  name: string;
  type: string;
};

type DashboardOrganizationScreenProps = {
  organization: Organization;
};

const DashboardOrganizationScreen = (
  props: DashboardOrganizationScreenProps
) => {
  return (
    <Switch>
      <Route
        path="/dashboard/organizations/:organizationId/home"
        component={DashboardHomeScreen}
      />
      <Route
        path="/dashboard/organizations/:organizationId/activity"
        component={DashboardActivityScreen}
      />
      <Route
        path="/dashboard/organizations/:organizationId/wallets/:walletId"
        component={OrganizationWalletsScreen}
      />
      <Route
        path="/dashboard/organizations/:organizationId/wallets"
        component={OrganizationWalletsScreen}
      />
      <Route
        path="/dashboard/settings/organizations/create"
        component={OrganizationCreateScreen}
      />
      <Route
        path="/dashboard/organizations/:organizationId/settings/projects/create"
        component={ProjectCreateScreen}
      />
      <Route
        path="/dashboard/settings"
        component={OrganizationSettingsScreen}
      />
      <Route
        path="/dashboard/organizations/:organizationId/team/:accountId"
        component={OrganizationTeamScreen}
      />
      <Route
        path="/dashboard/organizations/:organizationId/team/"
        component={OrganizationTeamScreen}
      />
      {/* 
      After a user logs in, they are sent to /dashboard, where we check their account
      and redirect to their selected organization
      */}
      <Route
        exact
        path="/dashboard"
        render={() => (
          <Redirect
            to={`/dashboard/organizations/${props?.organization?.id}/home`}
          />
        )}
      />
      {/*
        Handle uncaught routes with error screen
      */}
      <Route path="*" render={() => <Redirect to="/error" />} />
    </Switch>
  );
};
export default DashboardOrganizationScreen;
