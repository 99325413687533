import "./UmojaDropdownSelector.css";
import { useHistory } from "react-router-dom";
import { MenuItem, Button } from "@blueprintjs/core";
import { Select, ItemRenderer } from "@blueprintjs/select";
import UmojaAvatarIcon from "../Icons/UmojaAvatarIcon";

type Organization = {
  id: number;
  name: string;
  type: string;
};

const OrganizationSelect = Select.ofType<Organization>();

const renderOrganization: ItemRenderer<Organization> = (
  organization,
  { handleClick, modifiers }
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  return (
    <MenuItem
      active={modifiers.active}
      key={organization.id}
      onClick={handleClick}
      text={organization.name}
      className="sidebar__menu-list_item"
      icon={
        <UmojaAvatarIcon
          alt="Selected Organization"
          fallbackLetter={organization.name[0]}
          fallbackTextColor="#ffffff"
          fallbackBgColor="#6200ee"
        />
      }
    />
  );
};

export interface UmojaDropdownSelectorProps {
  organizations: any;
  selectedOrganization: Organization;
}

export const UmojaDropdownSelector = ({
  organizations,
  selectedOrganization,
}: UmojaDropdownSelectorProps) => {
  const history = useHistory();

  return (
    <OrganizationSelect
      filterable={false}
      popoverProps={{
        minimal: true,
      }}
      items={organizations}
      activeItem={selectedOrganization}
      itemRenderer={renderOrganization}
      onItemSelect={(organization, event) => {
        localStorage.setItem("selected_organization_id", JSON.stringify(organization.id));
        history.push(`/dashboard/organizations/${organization.id}/home`);
      }}
    >
      <Button
        className="organization__select-button"
        text={selectedOrganization?.name}
        icon={
          <UmojaAvatarIcon
            alt="Selected Organization"
            fallbackLetter={selectedOrganization?.name[0]}
            fallbackTextColor="#ffffff"
            fallbackBgColor="#6200ee"
          />
        }
        alignText="left"
        rightIcon="caret-down"
      />
    </OrganizationSelect>
  );
};

export default UmojaAvatarIcon;
