import {
  format,
  isWithinInterval,
  subDays,
  subHours,
  subYears,
} from "date-fns";

const dateFormat = "MMM dd H:mm";

interface TimeframeSelectProps {
  data: any;
  timeframe: any;
}

export const timeframedOfflineTransactions = ({
  data,
  timeframe,
}: TimeframeSelectProps) => {
  if (!data.transactions.length) {
    // handle empty dataset
    // return [
    //   { x: format(subDays(new Date(), 1), dateFormat), y: 0 },
    //   { x: format(new Date(), dateFormat), y: 0 },
    // ];
    return []
  }
  return data.transactions
    .filter((transaction: any) => {
      return (
        isWithinInterval(new Date(transaction.received_timestamp), {
          start: new Date(timeframe.start),
          end: new Date(timeframe.end),
        }) && transaction.is_offline
      );
    })
    .map((filteredTransaction: any) => {
      return {
        x: format(new Date(filteredTransaction.received_timestamp), dateFormat),
        y: filteredTransaction.amount,
      };
    });
};

export const timeframedTransactionAmounts = ({
  data,
  timeframe,
}: TimeframeSelectProps) => {
  if (!data.transactions.length) {
    // handle empty dataset
    // return [
    //   { x: format(subDays(new Date(), 1), dateFormat), y: 0 },
    //   { x: format(new Date(), dateFormat), y: 0 },
    // ];
    return []
  }
  return data.transactions
    .filter((transaction: any) => {
      return isWithinInterval(new Date(transaction.received_timestamp), {
        start: new Date(timeframe.start),
        end: new Date(timeframe.end),
      });
    })
    .map((filteredTransaction: any) => {
      return {
        x: format(new Date(filteredTransaction.received_timestamp), dateFormat),
        y: filteredTransaction.amount,
      };
    });
};

export const selectTimeframe = (timeframe: any) => {
  let currentTime = new Date();
  let min = subDays(currentTime, 365 * 2);
  let startTime;
  let endTime;

  switch (timeframe) {
    // the past hour
    case "timeframe-1h": {
      startTime = subHours(currentTime, 1);
      endTime = currentTime;
      break;
    }
    // the past 2 hours
    case "timeframe-24h": {
      startTime = subHours(currentTime, 24);
      endTime = currentTime;
      break;
    }
    // the past week
    case "timeframe-1w": {
      startTime = subDays(currentTime, 7);
      endTime = currentTime;
      break;
    }
    // past year
    case "timeframe-1y": {
      startTime = subYears(currentTime, 1);
      endTime = currentTime;
      break;
    }
    // all data
    case "timeframe-max": {
      startTime = min;
      endTime = currentTime;
      break;
    }
    // fallback
    default: {
      startTime = min;
      endTime = currentTime;
      break;
    }
  }

  return {
    start: startTime,
    end: endTime,
  };
};

export const findSum = (data: [] = []) => {
  let sum = data.reduce(
    (total: number, transaction: any) => total + transaction.amount,
    0
  );

  return sum;
};

export const spendingPatternData = (data: any) => {
 const spending =  data?.map((values: any) => ({
    x: format(new Date(values?.date), 'MM/dd' ),
    y: Number(values?.amount),
  }));

  return spending?.reverse()
};

export const formatDemographicData = (data: any) => {
  let newData = [];

  // get all categories
  if (data?.length) {
    const categoryNames = data.map((value: any) => value.name);
    newData.push({ categoryNames: categoryNames });

    // compute male demographic data
    const male = data.map((value: any) => {
      return {
        x: value.name,
        y: Number(value.male.total),
        z: Number(value.total),
      };
    });
    newData.push({ male: male });

    // compute female demographic data
    const female = data.map((value: any) => {
      return {
        x: value.name,
        y: Number(value.female.total),
        z: Number(value.total),
      };
    });
    newData.push({ female: female });

    // compute LGBTQ demographic data
    const LGBTQ = data.map((value: any) => {
      return {
        x: value.name,
        y: Number(value.lgbtq.total),
        z: Number(value.total),
      };
    });
    newData.push({ LGBTQ: LGBTQ });

    // compute "other" demographic data
    const other = data.map((value: any) => {
      return {
        x: value.name,
        y: Number(value.other.total),
        z: Number(value.total),
      };
    });
    newData.push({ other: other });

    // data to display total amount spent by category
    const empty = data.map((value: any) => {
      return {
        x: value.name,
        y: 0,
        z: Number(value.total),
      };
    });
    newData.push({ empty: empty });
  }

  return newData;
};
