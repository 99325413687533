import "./UmojaChartWidget.css";
import { ReactNode } from "react";
import {
  VictoryBar,
  VictoryLegend,
  PaddingProps,
  DomainPaddingPropType,
  VictoryTooltip, VictoryChart, VictoryAxis,
} from "victory";
import { useTranslation } from "react-i18next";

export interface UmojaSpendingPatternChartProps {
  title: ReactNode;
  data: Record<string, string | number>[];
  theme: any;
  padding: PaddingProps;
  domainPadding: DomainPaddingPropType;
  chartFooter?: String | number;
  currency: string
}

export const UmojaSpendingPatternChart = ({
  title,
  data,
  theme,
  padding,
  domainPadding,
  chartFooter,
  currency
}: UmojaSpendingPatternChartProps) => {
  const { t } = useTranslation();

  if (!data?.length) {
    return <div>{ t("no_data")}</div>;
  }


  return (
    <>
      <div className="spending_pattern_chart">
        <VictoryChart
          domainPadding={{ x: 0 }}
        >
          <VictoryLegend
            x={0}
            y={15}
            data={[]}
            title={`${title}`}
            style={{ title: { fontSize: 10, fontWeight: 600, fontFamily: "SFPro"} }}
          />
          <VictoryBar
            data={data.slice(-7)}
            barRatio={0.3}
            cornerRadius={{ top: 2 }}
            style={{
              data: { fill: "#6200ee", width: 4 }
            }}
            labelComponent={
              <VictoryTooltip
                pointerLength={20}
                cornerRadius={0}
                flyoutStyle={{ fill: "white", strokeWidth: 0.5 }}
                flyoutPadding={{ top: 2, bottom: 2, left: 7, right: 7 }}
              />
            }
            labels={({ datum }) => [`Date: ${datum.x}`, `Amount: ${currency}${(datum.y as number).toFixed(2)}`]}
          />
          <VictoryAxis
            style={{
              tickLabels: {
                fontSize: 8,
                fontFamily: "SFPro",
                fontWeight: 500
              }
            }}
          />
        </VictoryChart>
      </div>
      {chartFooter && <div className="spending_pattern_chart__footer">
        <span>{chartFooter}%</span> {`vs ${t('last_7_days')}`}
      </div>}
    </>
  );
};

export default UmojaSpendingPatternChart;


