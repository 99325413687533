import {
  BrowserRouter,
  Route,
  Switch,
  RouteProps,
  Redirect,
  useLocation,
} from "react-router-dom";
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";

import LoginScreen from "./screens/Login/Login";
import NotFoundScreen from "./screens/Errors/NotFoundScreen";
import SignUpScreen from "./screens/SignUp";
import VerifyAccountScreen from "./screens/VerifyAccount";
import DashboardContainer from "./containers/Dashboard";
import PrivateRoute from "./components/CustomRoute/privateRoute";
import PublicRoute from "./components/CustomRoute/publicRoute";
import ResetPassword from "./screens/ResetPassword/index";
import RedeemInvite from "./screens/RedeemInvite/index";

import { useAuth } from "./hooks";
import { UmojaToast } from "./components/Popups/UmojaToast";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
  // https://react-query.tanstack.com/reference/QueryCache
  queryCache: new QueryCache({
    onError: (error, query) => {
      console.error("Query Error: ", error);
      UmojaToast.show({
        message: "There was a problem making a request",
        intent: "warning",
        icon: "info-sign",
      });
    },
  }),
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Switch>
          <PublicRoute exact path="/sign-up" component={SignUpScreen} />
          <PublicRoute exact path="/reset-password" component={ResetPassword} />
          <PublicRoute exact path="/redeem-invite" component={RedeemInvite} />
          <PublicRoute
            exact
            path="/verify"
            component={(props: RouteProps) => {
              const params = new URLSearchParams(props.location?.search);
              const token = params.get("token");
              const email = params.get("email");
              return <VerifyAccountScreen token={token} email={email} />;
            }}
          />
          <PrivateRoute path="/dashboard" component={DashboardContainer} />
          <UnhandledRoute />
        </Switch>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

interface UnhandledRouteProps {
  path?: string;
  exact?: boolean;
}

/**
 * Unhandled routes for unauthenticated users should resolve to login screen
 * Unhandled routes for authenticated users should resolve to Error Screen
 */
const UnhandledRoute = (props: UnhandledRouteProps) => {
  const location = useLocation();
  const { ...rest } = props;
  const { user } = useAuth();

  // self heal root private route to dashboard
  if (user && location.pathname === "/") {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (user ? <NotFoundScreen /> : <LoginScreen />)}
    />
  );
};

export default App;
