import { useState } from "react";
import { Icon } from "@blueprintjs/core";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  UmojaPaymentWidget,
  AnalyticsCard,
  TransactionCard,
  Button,
} from "../../components";
import { ProjectDropDownSelect } from "../../components/Dropdown/ProjectDropDownSelector";
import { useRole } from "../../hooks";
import { downloadData } from "../../services";
import useGroups from "../../hooks/useGroups";
import useGroupWallets from "../../hooks/useGroupWallets";
import UmojaSpendingPatternChart from "../../components/Widgets/ChartWidget/UmojaSpendingPatternChart";
import { theme } from "../../themes/chartTheme";
import {
  formatDemographicData,
  spendingPatternData,
} from "../ActivityExplorer/util";
import UmojaDemographicInsightChart from "../../components/Widgets/ChartWidget/UmojaDemographicInsightChart";
import { mapCurrencySign } from "../../utils/currencyMap";
import { AtLeast } from "../../hooks/useRole";
import { formatDecimalNumber } from "../../utils";

export type RecipientWallet = {
  name: string;
  wallet_ids: number[];
};

export type Project = {
  name: string;
  id: number;
};

export type OrganizationWalletProps = {
  id: number;
  name: string;
  balance: string;
  currency: string;
};

type ProjectsProps = {
  id: number;
  name: string;
};

type ProjectStatsProps = {
  avg_transaction_balance: number;
  total_transactions: number;
  beneficiares_count: number;
  total_transactions_amount: number;
  total_disbursements_amount: number;
  merchants_count: number;
  total_spent: {
    PURCHASE: {
      total: string;
    }
  };
  volume: Array<{}>;
  vs_last_week_percentage: number;
  gender_stats: {
    female_total_transactions: number;
    male_total_transactions: number;
    other_total_transactions: number;
  };
  total_refunded_amount: string
};
export interface GlobalOperationsProps {
  programWallet: OrganizationWalletProps;
  transactionsHistory: any[];
  project: Project;
  averageBalance: any;
  projectStats: ProjectStatsProps;
  projects: ProjectsProps[];
  demographicInsightStats: any;
}

export const GlobalOperations = ({
  programWallet,
  project,
  transactionsHistory,
  averageBalance,
  projectStats,
  projects,
  demographicInsightStats,
}: GlobalOperationsProps) => {
  const params = useParams<{ organizationId: string }>();
  const role = useRole();
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const progCurrency = mapCurrencySign[programWallet?.currency];
  const download = async (project: Project) => {
    setDisabled(true);
    const data = await downloadData(params.organizationId, project.id);
    const blob = await data.blob();
    const href = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", "exported_program_data.xlsx"); //or any other extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setDisabled(false);
  };

  const [selectedGroup, setSelectedGroup] = useState("");
  const groups = useGroups(params.organizationId, project?.id);
  const groupWallets = useGroupWallets(
    params.organizationId,
    project?.id,
    selectedGroup
  );
   
  // get spending pattern volume
  const spendingPatternVolume = spendingPatternData(projectStats?.volume);

  // get demographic insight data
  const demographicInsightData = formatDemographicData(demographicInsightStats?.categories);
  
  return (
    <div
      className={`${AtLeast.Organizer(role) ? "" : "global__operations-block-view"
        } global__operations-outer-wrapper`}
    >
      <div>
        <div className="global__operations-widget__header">
          <ProjectDropDownSelect projects={project} />
          <Button
            minimal
            onClick={() => download(project)}
            text={t("export_data")}
            disabled={disabled}
            className="global__operations-widget-export-button"
            icon={
              <Icon
                icon="download"
                iconSize={15}
                color="#BFCCD6"
                style={{ paddingBottom: 2 }}
              />
            }
          />
        </div>
        <div
          className={"global__operations-widget-grid"}
        >
          <div className="global__operations-widget-grid-left">
            <div className="global__operations-widget-stats">
              <AnalyticsCard
                title={`${t("participant_pulse")}`}
                leftHeader={`${t("merchants")}`}
                leftContent={projectStats?.merchants_count}
                rightHeader={`${t("recipients")}`}
                rightContent={projectStats?.beneficiares_count}
              />
              <AnalyticsCard
                title={`${t("flow_of_funds")}`}
                leftHeader={`${t("total_txns")} (${progCurrency})`}
                leftContent={projectStats?.total_transactions}
                rightHeader={`${t("average_txn")} (${progCurrency})`}
                rightContent={`${formatDecimalNumber(String(projectStats?.avg_transaction_balance))}`}
                isLoading={projectStats?.avg_transaction_balance === undefined ? true : false}
              />
            </div>
            <div className="global__operations-widget-transactions">
              <TransactionCard
                title={`${t("merchant_wallet_balance")} (${progCurrency})`}
                content={`${formatDecimalNumber(averageBalance?.data?.avg_merchants_balance)}`}
                isLoading={averageBalance?.isFetching}
              />
              <TransactionCard
                title={`${t("total_disbursed")} (${progCurrency})`}
                content={`${formatDecimalNumber(String(projectStats?.total_disbursements_amount))}`}
                isLoading={projectStats?.total_disbursements_amount === undefined ? true : false}
              />
              <TransactionCard
                title={`${t("recipient_wallet_balance")} (${progCurrency})`}
                content={`${formatDecimalNumber(averageBalance?.data?.avg_beneficiaries_balance)}`}
                isLoading={averageBalance?.data?.avg_beneficiaries_balance === undefined ? true : false}
              />
              <TransactionCard
                title={`${t("total_spent")} (${progCurrency})`}
                content={`${projectStats?.total_spent?.PURCHASE?.total === "null"
                  ? 0
                  : formatDecimalNumber(projectStats?.total_spent?.PURCHASE?.total)}`}
                isLoading={projectStats?.total_spent === undefined ? true : false }
              />
              <div></div>
              <TransactionCard
                title={`${t("total_refunded")} (${progCurrency})`}
                content={`${projectStats?.total_refunded_amount === "null"
                  ? 0
                  : formatDecimalNumber(projectStats?.total_refunded_amount)
                  }`}
                isLoading={projectStats?.total_refunded_amount === undefined ? true : false}
              />
            </div>
            <div style={{ marginTop: "-3%" }}>
              <h3 className="demographic_insight-chart_header">{`${t(
                "demographic_insight"
              )}`}</h3>
              <UmojaDemographicInsightChart
                theme={theme.customStyle}
                title={`${t("spending_by_gender")}`}
                data={demographicInsightData}
                padding={{ top: 40, left: 130, bottom: 20, right: 80 }}
                tickValues={demographicInsightData[0]?.categoryNames}
                currency={progCurrency ?? ""}
              />
            </div>
            <div>
              <h3 className="demographic_insight-chart_header">{`${t(
                "spending_pattern"
              )}`}</h3>
              <UmojaSpendingPatternChart
                title={`${t("volume_transacted")}`}
                data={spendingPatternVolume}
                theme={theme.customStyle}
                padding={{ top: 0, left: -50, bottom: 20, right: -50 }}
                domainPadding={{ x: 0, y: 30 }}
                chartFooter={projectStats?.vs_last_week_percentage?.toFixed(1)}
                currency={progCurrency}
              />
            </div>
          </div>
          {AtLeast.Admin(role) ? (
            <UmojaPaymentWidget
              disabled={false}
              wallets={groups}
              masterWalletBalance={programWallet?.balance}
              disbursementHistory={transactionsHistory}
              project={project}
              disbursementWallets={groupWallets}
              setSelectedGroup={setSelectedGroup}
              currency={progCurrency}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
