export async function getDemographicInsightStats(
  organizationId: number,
  projectId: number
) {
  const response = await fetch(
    `${process.env.REACT_APP_UMOJA_MANAGEMENT_API_DOMAIN}/organizations/${organizationId}/projects/${projectId}/categories-stats`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );

  const data = await response.json();
  return data;
}
