import { HTMLSelect } from "@blueprintjs/core";
import { useField } from "react-form";
import { TextInput } from "../../../../../components";
import { useTranslation } from "react-i18next";

interface ProjectFocusInputProps {
  defaultValue: string;
  readOnly: boolean;
}

export const ProjectFocusInput = ({
  defaultValue,
  readOnly,
}: ProjectFocusInputProps) => {
  const { t } = useTranslation()

  const focus_types = [
    { label: t("health"), value: "health" },
    { label: t('nutrition'), value: 'nutrition' },
    { label: t('food_security'), value: 'food_security' },
    { label: t('cash_assistance'), value: 'cash_assistance' },
    { label: t('voucher_assistance'), value: 'voucher_assistance' },
    { label: t('housing_shelter'), value: 'housing_shelter' },
    { label: t('water_and_sanitation'), value: 'water_and_sanitation' },
    { label: t('livelihood_and_economic_support'), value:'livelihood_and_economic_support'  },
    { label: t('education'), value: 'education' },
    { label: t('gender_equality'), value: 'gender_equality'}
  ]

  const validateInput = (input: string) => {
  if (input.length < 1) {
    return t("project_focus_validation");
  }
  return false;
};
  const ProjectFocusInputFieldInstance = useField("project_focus", {
    validate: validateInput,
    defaultIsTouched: false,
    defaultValue: defaultValue || "",
  });

  const { meta, getInputProps } = ProjectFocusInputFieldInstance;
  const focus_type = focus_types.filter(d => d.value === defaultValue)


  if (readOnly) {
    return (
      <li className="settings__card-input-list-item">
        <label>{t('project_focus')}</label>
        <div style={{ height: 50, width: 200 }}>
          <TextInput
            {...getInputProps()}
            type="text"
            placeholder={focus_type[0]?.label}
            value={t(`${focus_types[0]?.value}`)}
            name="projectName"
            readOnly={readOnly}
          />
          {meta.isTouched && (
            <p style={{ fontSize: 10, color: "red", padding: 0, margin: 0 }}>
              {/* {meta.error} */}
            </p>
          )}
        </div>
      </li>
    );
  }

  return (
    <li className="settings__card-input-list-item">
      <label>{t('project_focus')}</label>
      <div style={{ height: 50, width: 200 }}>
        <HTMLSelect
          {...getInputProps()}
          options={[
            {
              label: focus_type[0]?.label || t('select_focus'),
              value: focus_types[0]?.value || "",
            },
            ...focus_types
          ]}
          required
          name="projectFocus"
        />
        {meta.isTouched && (
          <p style={{ fontSize: 10, color: "red", padding: 0, margin: 0 }}>
            {meta.error}
          </p>
        )}
      </div>
    </li>
  );
};


