import { format, subDays } from "date-fns";
import { OrganizationWallet } from "../../../components/Widgets/PaymentWidget/UmojaPaymentWidget";
const dateFormat = "MMM dd H:mm:ss";

interface DashboardFeaturedChartProps {
  data: any;
}

// Plot all transaction amounts on a line chart
export const grossTransactionAmount = ({
  data,
}: DashboardFeaturedChartProps) => {
  if (!data.length) {
    // handle empty dataset
    return [
      { x: format(subDays(new Date(), 1), dateFormat), y: 0 },
      { x: format(new Date(), dateFormat), y: 0 },
    ];
  }
  return data.map((transaction: any) => {
    return {
      x: format(new Date(transaction.received_timestamp), dateFormat),
      y: transaction.amount,
    };
  });
};

// Group transactions by is_offline flag
export const groupTransactionsByOfflineStatus = ({
  data,
}: DashboardFeaturedChartProps) => {
  if (!data.transactions) {
    // handle empty dataset
    return [
      { x: "online", y: 0 },
      { x: "offline", y: 0 },
    ];
  }
  let online = 0;
  let offline = 0;

  data.transactions.forEach((transaction: any) => {
    if (transaction.is_offline) {
      offline++;
    }
    if (!transaction.is_offline) {
      online++;
    }
  });
  return [
    { x: "online", y: online },
    { x: "offline", y: offline },
  ];
};

// Total transaction volume. Increment subtotals, in progressively ascending line
export const totalIncrementingTransactionVolume = ({
  data,
}: DashboardFeaturedChartProps) => {
  if (!data.transactions.length) {
    // handle empty dataset
    return [
      { x: format(subDays(new Date(), 1), dateFormat), y: 0 },
      { x: format(new Date(), dateFormat), y: 0 },
    ];
  }
  let subTotal = 0;
  return data.transactions.map((mappedTransaction: any) => {
    subTotal = subTotal + mappedTransaction.amount;
    return {
      x: format(new Date(mappedTransaction.received_timestamp), dateFormat),
      y: subTotal,
    };
  });
};

// Demographic breakdown by age_group
export const segmentOrganizationAgeGroup = ({
  data,
}: DashboardFeaturedChartProps) => {
  // Kobo forms age grouping config
  let koboAgeGroupConfig: Record<
    number,
    { age_range: string; count: number }
  > = {
    0: { age_range: "Unknown", count: 0 },
    1: { age_range: "<18", count: 0 },
    2: { age_range: "19-30", count: 0 },
    3: { age_range: "30-40", count: 0 },
    4: { age_range: "40-50", count: 0 },
    5: { age_range: "50-60", count: 0 },
    6: { age_range: "60+", count: 0 },
  };

  if (!data) {
    return [
      { x: "Unknown", y: 0 },
      { x: "<18", y: 0 },
      { x: "19-30", y: 0 },
      { x: "30-40", y: 0 },
      { x: "40-50", y: 0 },
      { x: "50-60", y: 0 },
      { x: "60+", y: 0 },
    ];
  }

  data.forEach((mappedTransaction: OrganizationWallet) => {
    const ageGroup = mappedTransaction.age_group;
    if (ageGroup) {
      koboAgeGroupConfig[ageGroup].count++;
    } else {
      koboAgeGroupConfig[0].count++;
    }
  });

  return Object.entries(koboAgeGroupConfig).map((segment) => {
    let data = segment[1];
    return {
      x: data.age_range,
      y: data.count,
    };
  });
};

export const calculateTotalTransactionVolume = (
  amount: string | number,
  totalRecipients: string | number
) => {
  return (Number(amount) * Number(totalRecipients)).toFixed(2);
};

export const getMasterWalletBalance = (
  transactionVolume: string | number,
  presentBalance: string | number
) => {
  return (Number(presentBalance) - Number(transactionVolume)).toFixed(2);
};

// TODO: Query the following graphs on homepage:
// Transaction volume
// Count of successful transactions per day
// Count of failed transactions per day

export const getGroupsData = (groupsQuery: any, t: any) => {
  if (!groupsQuery.data) {
    return [];
  }
};
